import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Headline from '@elements/Headline'
import SubHeadline from '@elements/SubHeadline'
import BackgroundSvg from '@elements/BackgroundSvg'
import flower_01 from '@images/flower_1.png'
import flower_02 from '@images/flower_2.png'
import kathi from '@images/kathi_without_hand.png'
import kathiTotale from '@images/kathi_totale.png'
import kathiHand from '@images/kathi_hand.png'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useStateContext } from '@context/stateContext'
import { AnimatePresence, motion } from 'framer-motion'
import { Landing, notSafari } from './LandingHeader'
import {
  fadeInOut,
  MotionFadeZoom,
  secondScreenDelay,
  fadeInOutv2,
} from '@elements/Animations'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StyledSection = styled.div`
  ${tw`w-full relative px-5 xxs:min-h-[560px]
md:min-h-[900px] lg:min-h-[534px] 3xl:min-h-[700px] text-gloriole
 max-h-[888px] md:max-h-[1200px] xl:max-h-[888px] lg:pb-7`};
  height: calc(100vh - 64px);
  @media screen and (min-width: 1024px) {
    height: calc(100vh - 136px);
  }
  @media screen and (max-width: 1023px) and (min-height: 664px) {
    height: calc(100vh - 80px);
  }
  .safari_fix {
    min-width: calc(100% + 32px);
  }
  .safari_mobile_fix {
    @media screen and (max-width: 1023px) {
      min-width: calc(100% + 32px);
    }
  }
`
const MobileWrapper = tw.div`z-20 flex flex-col justify-start w-full h-full items-center`
const StyledButton = styled.button`
  ${tw`relative font-lobster h-[59px] lg:h-[65px] 3xl:h-[77px] w-full max-w-[560px] lg:max-w-[450px] lg:min-w-[450px] 3xl:min-w-[560px] xl:max-w-[560px] border-2 text-18px lg:text-3xl 3xl:text-40px block text-white`}
  border: 3px solid #cccccc;
  border-radius: 6px;
  transform-style: preserve-3d;
  &:before {
    content: '';
    position: absolute;
    transform: translateZ(-1px);
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background: linear-gradient(
      180deg,
      #028450 10.94%,
      #006e42 40.1%,
      #006e42 62.14%,
      #005734 90.62%
    );
  }
  &:after {
    content: '';
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translateZ(-1px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: linear-gradient(
      180deg,
      #03a061 10.94%,
      #008651 40.1%,
      #038651 62.14%,
      #027c4b 90.62%
    );
  }
  &:hover {
    box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.32);
    &:after {
      opacity: 1;
    }
  }
`
const AgeText = tw.p`my-2.5 3xl:mb-0 text-13px xxs:text-[15px] 3xl:text-18px font-roboto font-light`
const KathiWrapper = styled(motion.div)`
  ${tw`absolute bottom-0 z-30 lg:left-[0] xl:left-[8%] 2xl:left-[12%] 3xl:left-[13%] fhd:left-[17%] 2k:left-[19%] h-[639px] w-[543px]
  flex-shrink-0 scale-40 xxs:scale-50 md:scale-65 lg:scale-75 xl:scale-[0.8] 1500px:scale-100 3xl:scale-100 2k:scale-105 origin-top
  lg:origin-bottom flex justify-end`};
`
const Glow = styled(motion.div)`
  ${tw`absolute 3xl:-top-4 lg:top-12 left-1/4 h-2/3 bg-white w-2/3 blur-[214px] z-[-1] opacity-[0.4] hidden lg:block`}
  transform: translateZ(0);
`
const Wrapper = styled.div`
  ${tw`relative z-20 flex flex-col items-center justify-start w-full h-full pt-4 overflow-hidden lg:justify-center xxs:pt-4 lg:pt-0 `};
  @media screen and (max-width: 1023px) and (min-height: 664px) {
    ${tw`pt-16`};
  }
`

const MobileKathi = styled(motion.div)`
  ${tw`flex justify-center items-start z-20 absolute w-[191px] top-28 xxs:top-30 transition-all`};
  @media screen and (max-width: 1023px) and (min-height: 664px) {
    ${tw`top-[200px]`};
  }
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
`
const StyledButtons = styled(motion.div)`
  ${tw`w-full flex flex-col justify-center text-center items-center z-20 top-64 xxs:top-[264px] lg:top-[unset] absolute max-w-[90%] lg:relative`}
  @media screen and (max-width: 1023px) and (min-height: 664px) {
    ${tw`top-[350px]`};
  }
`

export const AgeVerification = ({ content, className }) => {
  const {
    age,
    setAge,
    firstScreenActive,
    ageFalseActive,
    secondScreenActive,
    thirdScreenActive,
    activeScreen,
    setActiveScreen,
  } = useStateContext()
  const { isTablet, isMaster } = useBreakpoint()
  const heavyAnimations = notSafari || isMaster
  const { t } = useTranslation()

  React.useEffect(() => {
    if (age === true && activeScreen !== 3) {
      setActiveScreen(2)
      setTimeout(() => {
        setActiveScreen(3)
      }, 1550)
    }
    if (age === false) {
      setActiveScreen(null)
    }
  }, [age])

  const Buttons = () => {
    return (
      <StyledButtons>
        <MotionFadeZoom
          delay={1.1}
          className="flex flex-col items-center justify-center min-w-full"
        >
          <StyledButton onClick={() => setAge(true)}>
            {t('ONE_ALWAYS')}
          </StyledButton>
          <AgeText tw="mb-4 3xl:mb-10">
            {t('AGE_YES')}
          </AgeText>
        </MotionFadeZoom>
        <MotionFadeZoom
          delay={1.3}
          className="flex flex-col items-center justify-center min-w-full"
        >
          <StyledButton onClick={() => setAge(false)}>
            {t('TOO_YOUNG')}
          </StyledButton>
          <AgeText>{t('AGE_NO')}</AgeText>
        </MotionFadeZoom>
      </StyledButtons>
    )
  }
  const Headlines = ({ className }) => {
    const [animationState, setAnimationState] = useState(0)
    return (
      <motion.div className={className}>
        <MotionFadeZoom delay={0.5}>
          <Headline
            label={t('HI')}
            tw="text-69px lg:text-[120px] 1500px:text-130px 3xl:text-160px mb-0 lg:mb-8 1500px:mb-14 3xl:mb-20"
            className="safari_mobile_fix"
          />
        </MotionFadeZoom>
        <motion.div
          onAnimationStart={() => {
            animationState === 0 && setAnimationState(1)
          }}
          onAnimationComplete={() => {
            animationState === 1 && setAnimationState(2)
          }}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={
            animationState === 0
              ? { opacity: 0, scale: 0.9 }
              : animationState === 1
              ? {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: 'tween',
                    ease: 'easeInOut',
                    duration: 0.3,
                    delay: 0.5,
                  },
                }
              : {
                  opacity: 1,
                  scale: [1, 1.15, 1],
                  transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                    delay: 3,
                    repeatDelay: 3,
                    repeat: Infinity,
                  },
                }
          }
          exit={{
            opacity: 0,
            scale: 1.05,
            transition: {
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.3,
            },
          }}
        >
          <SubHeadline
            label={t('WANT_BEER')}
            tw="text-32px lg:text-80px lg:mb-8 xl:mb-10 1500px:mb-16 3xl:mb-20 text-center lg:text-left"
          />
        </motion.div>
      </motion.div>
    )
  }
  const GoHereText = ({ className = '' }) => {
    return (
      <MotionFadeZoom
        delay={secondScreenDelay}
        className={`${className} h-[80px] z-0`}
      >
        <Headline
          label={t('COME_IN')}
          className="safari_fix"
          tw="mb-0 text-69px lg:text-160px 2xl:text-180px lg:mb-8 xl:mb-14 2xl:mb-90px"
        />
      </MotionFadeZoom>
    )
  }
  const DrinkResponsiblyText = () => {
    return (
      <MotionFadeZoom
        delay={secondScreenDelay}
        tw="absolute top-10 px-5 sm:mx-[5vw] max-w-[90%] lg:mx-0 lg:relative lg:px-[17vw] w-full 3xl:max-w-[900px] box-content z-20 text-white font-lobster lg:h-full"
      >
        <p tw="text-32px md:text-5xl lg:text-6xl 3xl:text-75pxv2">
          {t('DRINK_RESPONSIBLY')} <br /> {t('BROWSE_RESPONSIBLY')}
        </p>
        <p tw="text-25px md:text-4xl lg:text-5xl 3xl:text-50px">
          {t('OVER_16_REQUIRED')}
        </p>
        <StyledButton
          onClick={() => {
            setActiveScreen(1)
            setAge(null)
          }}
          tw="mt-7 text-white"
        >
          {t('COME_BACK')}
        </StyledButton>
      </MotionFadeZoom>
    )
  }

  const SocialIcons = ({ className }) => {
    return (
      <motion.div
        className={className}
        variants={fadeInOutv2}
        initial={secondScreenActive ? 'hidden' : 'visible'}
        animate="visible"
        tw="absolute right-5 top-1/4 flex flex-col z-40"
      >
        {/* <a href={content.igLink} target="_blank" tw="mb-4"> */}
        <a href={content.igLink} target="_blank">
          <img src={content.ig.default} alt={content.igText} tw="h-5"></img>
        </a>
        {/* <a href={content.fbLink} target="_blank">
          <img src={content.fb.default} alt={content.fbText} tw="h-5"></img>
        </a> */}
      </motion.div>
    )
  }

  const StripesBackground = () => (
    <BackgroundSvg
      animate={thirdScreenActive && !isTablet && heavyAnimations}
      className="w-[1671px] h-[819px] md:w-[2200px] md:h-[1200px] absolute translate-x-[-200px] translate-y-[-170px] xxs:translate-y-[-100px] md:translate-x-[-250px] md:translate-y-[-100px] lg:translate-x-0 lg:translate-y-0"
    />
  )
  const Flowers = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.6,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.3,
        },
      }}
      tw="w-full h-full absolute top-0 left-0"
    >
      <img
        tw="object-cover absolute bottom-0 left-0 scale-40 origin-bottom-left lg:scale-75 3xl:scale-100"
        src={flower_01}
        alt="Hopfen"
      />
      <img
        tw="object-cover absolute top-0 right-0 scale-40 origin-top-right lg:scale-75 3xl:scale-100"
        src={flower_02}
        alt="Hopfen"
      />
    </motion.div>
  )

  const Kathi = ({ ...props }) => {
    const [animationState, setAnimationState] = useState(0)
    const getKathiPhoto = () => {
      if (!isTablet) return kathi
      if (secondScreenActive) return kathiTotale
      if (firstScreenActive) return kathiTotale
      return null
    }
    //woman
    if (isTablet && firstScreenActive)
      return (
        <MobileKathi
          className={`${firstScreenActive && 'overflow-hidden max-h-[150px]'}`}
        >
          <motion.img
            src={getKathiPhoto()}
            alt="Kathi"
            variants={fadeInOut}
            exit="exit"
            tw="w-full h-auto"
            {...props}
          />
        </MobileKathi>
      )
    if (isTablet && secondScreenActive)
      return (
        <MobileKathi>
          <motion.img
            src={getKathiPhoto()}
            alt="Kathi"
            initial={{ scale: 1.05, opacity: 0.9 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 0.35,
              },
            }}
            exit={{
              scale: 1.1,
              opacity: 0,
              transition: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 0.3,
              },
            }}
            tw="w-full h-auto"
            {...props}
          />
        </MobileKathi>
      )
    return (
      <KathiWrapper>
        <AnimatePresence exitBeforeEnter>
          {!isTablet && !thirdScreenActive && (
            <Glow
              key="glow_01"
              hidden={{ opacity: 0 }}
              show={{
                opacity: 0.4,
                transition: {
                  type: 'tween',
                  ease: 'easeInOut',
                  duration: 0.3,
                  delay: 0.3,
                },
              }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
        <motion.img
          src={getKathiPhoto()}
          alt="Kathi"
          tw=""
          initial={
            age === true
              ? { scale: 1, opacity: 1 }
              : { scale: 1, opacity: 0, y: 40 }
          }
          animate={{
            scale: age === true ? 1 : 1,
            opacity: 1,
            y: 0,
            transition: {
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.3,
              delay: 0.3,
            },
          }}
          exit={{
            scale: 1.1,
            opacity: 0,
            transition: {
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.3,
            },
          }}
          {...props}
        />
        <motion.img
          src={kathiHand}
          tw="absolute left-0 bottom-[35%] w-[103px]"
          alt="Kathi"
          onAnimationStart={() => {
            animationState === 0 && setAnimationState(1)
          }}
          onAnimationComplete={() => {
            animationState === 1 && setAnimationState(2)
          }}
          initial={
            age === true
              ? { scale: 1, opacity: 1 }
              : { scale: 1, opacity: 0, y: 40 }
          }
          animate={
            animationState === 0
              ? age === true
                ? { scale: 1, opacity: 1 }
                : { scale: 1, opacity: 0, y: 40 }
              : animationState === 1
              ? {
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: 'tween',
                    ease: 'easeInOut',
                    duration: 0.3,
                    delay: 0.3,
                  },
                }
              : {
                  opacity: 1,
                  scale: 1,
                  rotate: ['0deg', '6deg', '-6deg', '0deg'],
                  y: 0,
                  transition: {
                    ease: 'easeInOut',
                    duration: 0.75,
                    repeatDelay: 2,
                    repeat: Infinity,
                  },
                }
          }
          exit={{
            scale: 1.1,
            opacity: 0,
            transition: {
              type: 'tween',
              ease: 'easeInOut',
              duration: 0.3,
            },
          }}
        />
      </KathiWrapper>
    )
  }

  return (
    <StyledSection className={className}>
      {!isTablet && (
        <AnimatePresence exitBeforeEnter>
          {!thirdScreenActive && age !== false && <Kathi key="kathi" />}
        </AnimatePresence>
      )}
      <Wrapper>
        {
          // 1st page mobile
        }
        <AnimatePresence exitBeforeEnter initial={true}>
          {isTablet && firstScreenActive && (
            <MobileWrapper key="mob_wrapper_00">
              <Flowers />
              {firstScreenActive && <Headlines />}
              {!thirdScreenActive && age !== false && (
                <Kathi initial="hidden" animate="visible" />
              )}
              {firstScreenActive && <Buttons />}
            </MobileWrapper>
          )}
        </AnimatePresence>
        {
          // 2nd page mobile
        }
        <AnimatePresence exitBeforeEnter>
          {isTablet && secondScreenActive && (
            <MobileWrapper key="mob_wrapper_01">
              <GoHereText />
              <SocialIcons />
              <Kathi />
            </MobileWrapper>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {ageFalseActive && isTablet && (
            <DrinkResponsiblyText key="drink_resp" />
          )}
        </AnimatePresence>
        {
          // 1st page desktop
        }

        <AnimatePresence exitBeforeEnter>
          {!isTablet && firstScreenActive && <Flowers key="glow_01" />}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {firstScreenActive && (
            <div
              key="head_wrapper"
              tw="relative w-full lg:w-max lg:absolute lg:left-[45%] 3xl:left-[45%] flex flex-col justify-center text-center items-center lg:pt-20 3xl:pt-12"
            >
              {!isTablet && (
                <>
                  <Headlines />
                  <Buttons />
                </>
              )}
            </div>
          )}
        </AnimatePresence>
        {
          // 2nd page desktop
        }
        <AnimatePresence exitBeforeEnter>
          {!isTablet && secondScreenActive && (
            <div
              key="go_here"
              tw="relative w-full lg:w-[max-content] lg:absolute lg:left-[45%] 2xl:left-[39%] 3xl:left-[43%] flex flex-col justify-center text-center items-center"
            >
              <GoHereText />
            </div>
          )}
          {age === false && !isTablet && (
            <div key="drink_resp_02" tw="flex items-center">
              <DrinkResponsiblyText />
            </div>
          )}
        </AnimatePresence>
        {
          // Landing
        }
        <AnimatePresence exitBeforeEnter>
          <>
            {thirdScreenActive && isTablet && <SocialIcons key="socials" />}
            {age === true && <StripesBackground key="strp_bg_02" />}
            {thirdScreenActive && <Landing key="landing" />}
          </>
        </AnimatePresence>
      </Wrapper>
    </StyledSection>
  )
}
